import { useState, useEffect } from 'react';

function useDoubleClick(actionDoubleClick, clickCount, delay) {
    const [click, setClick] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setClick(0);
        }, delay);
        if (click === clickCount) actionDoubleClick();
        return () => clearTimeout(timer);
    }, [click]);

    return () => setClick(prev => prev + 1);
}

export default useDoubleClick;