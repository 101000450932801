import { textSubArr } from "js/function";
import { Fragment } from "react";

function PageContentsBox(data){
    return (
        <div className={`pageContentsBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function PageInputItem(data){
    return (
        <div className={`pageInputItem ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function PwItem(data){
    return (
        <p className={`PwItem ${data.addClass ? data.addClass : ""}`}>
            <span className={data.data.length >= 1 ? "active" : ""}></span>
            <span className={data.data.length >= 2 ? "active" : ""}></span>
            <span className={data.data.length >= 3 ? "active" : ""}></span>
            <span className={data.data.length >= 4 ? "active" : ""}></span>
            <span className={data.data.length >= 5 ? "active" : ""}></span>
            <span className={data.data.length >= 6 ? "active" : ""}></span>
        </p>
    );
}

function CarNumberItem(data){
    return (
        <p className={`CarNumberItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.data||"123가1234"}}/>
    );
}

function KeypadTitle(data){
    return (
        <div className="keypadTitleBox">
            <h1 className="keypadTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.subTitle ? <p className="keypadSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
            {data.errMsg ? <p className="keypadErrMsg" dangerouslySetInnerHTML={{__html:data.errMsg}}/> : ""}
        </div>
    );
}

function KeypadBtn(data){
    return (
        <>
            {data.contents || data.contents == 0 ? 
                <button type="button" onClick={()=>{data.func()}} disabled={data.disabled||false} className={`keypadBtn ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
                :
                <div className={`keypadBtn ${data.addClass ? data.addClass : ""}`}/>
            }
        </>
        
    );
}

function KeypadBox(data){
    return (
        <div className={`keypadBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function KeypadMsg(data){
    return (
        <p className={`keypadMsg ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function Keypad(data){
    let padData = data.type == "number" ?
    [
        {
            key : 1,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 2,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 3,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 4,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 5,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 6,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 7,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 8,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : 9,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "<img src='/assets/images/icon/pad_del.svg'/>",
            type : "back"
        },
        {
            key : 0,
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : data.allDel ? "전체삭제" : `<img src='/assets/images/icon/${data.nextChk ? "pad_next_on":"pad_next_off"}.svg'/>`,
            type : data.allDel ? "allDel" : data.nextChk ? "next" : "nextDisabled"
        }
    ]
    : data.type == "text" ?
    [
        {
            key : "ㄱ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㄴ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㄷ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㄹ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㅁ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㅂ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㅅ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㅇ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "ㅈ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : "<img src='/assets/images/icon/pad_del.svg'/>",
            type : "back"
        },
        {
            key : "ㅎ",
            type : data.textDisabled ? "disabled" : "text"
        },
        {
            key : `<img src='/assets/images/icon/${data.nextChk ? "pad_next_on":"pad_next_off"}.svg'/>`,
            type : data.nextChk ? "next":"nextDisabled"
        }
    ]
    : data.type == "textSub" ?
    [
        {
            key : textSubArr(data.textVal)[0],
            type : data.textDisabled ? "disabled" : "textSub"
        },
        {
            key : textSubArr(data.textVal)[1],
            type : data.textDisabled ? "disabled" : "textSub"
        },
        {
            key : textSubArr(data.textVal)[2],
            type : data.textDisabled ? "disabled" : "textSub"
        },
        {
            key : textSubArr(data.textVal)[3],
            type : data.textDisabled ? "disabled" : "textSub"
        },
        {
            key : "<img src='/assets/images/icon/pad_del.svg'/>",
            type : "back"
        }
    ]:[];

    return (
        <div className={`keypad ${data.addClass ? data.addClass : ""}`}>
            {padData && padData.map((item,i) => (
                <Fragment key={i}>
                    <KeypadBtn
                        contents={item.key}
                        addClass={item.type == "allDel" ? "allDel" : ""}
                        disabled={item.type == "allDel" ? data.data.length <= 0 ? true : false : item.type == "disabled" ? true : false}
                        func={()=>{
                            if(item.type == "text"){
                                data.textFunc(item.key)
                            }else if(item.type == "textSub"){
                                data.textSubFunc(item.key)
                            }else if(item.type == "back"){
                                data.backFunc()
                            }else if(item.type == "next"){
                                data.nextFunc()
                            }else if(item.type == "allDel"){
                                data.allDel()
                            }
                        }}
                    />
                </Fragment>
            ))}
        </div>
    );
}

function SettingMenuItem(data){
    return (
        <button type="button" onClick={()=>{data.func()}} disabled={data.disabled||false} className={`settingMenuItem ${data.addClass ? data.addClass : ""}`}>
            {data.text}
            <img src="/assets/images/icon/move.svg"/>
        </button>
    );
}

function StatePageBox(data){
    return (
        <div className={`statePage ${data.addClass||""}`}>
            {data.title ? 
            <div className="stateTitleBox">
                {data.icon ? <img src="/assets/images/icon/stateTitleIcon.svg"/> : ""}
                {data.subTitle ? <p className="stateSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
                <h1 className="stateTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            </div>
            :""}
            {data.img ? 
                <img src={data.img}/>
            :""}
            {data.children}
        </div>
    );
}

export {PageContentsBox,PageInputItem,PwItem,CarNumberItem,Keypad,KeypadBox,KeypadMsg,KeypadTitle,SettingMenuItem,StatePageBox};