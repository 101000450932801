import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { Header } from "component/elements/header";
import { CarNumberItem, Keypad, KeypadBox, KeypadMsg, KeypadTitle, PageContentsBox, PageInputItem } from "component/app/setting";
import socketio from "socket.io-client"
import Echo from "laravel-echo";

const CarNumber = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [carNumber,setCarNumber] =  useState("");
    
    const [errMsg,setErrMsg] =  useState(null);
    const [apiChk,setApiChk] =  useState(false);

    const [textChk,setTextChk] =  useState(null);
    const [subTextChk,setSubTextChk] =  useState(null);

    const carNumberPlaceholder = "123가1234";
    const carNumberSubPlaceholder = "12가1234";

    useEffect(() => {
        if(!window.Echo){
            window.Echo = new Echo({
                host: process.env.REACT_APP_SOCKET_URL, 
                broadcaster: "socket.io",
                client: socketio
            });
        }

        window.Echo.channel("laravel_database_entrance").listen("EntrancePushed", (e) => {
            if (e.cam_id == 'end') {
                navigate("/main");
            } else if(e.cam_id == process.env.REACT_APP_CAM){
                navigate("/service",{state: {carNumber:e.car_number}});
            }
        });
    }, []);

    useEffect(() => {
        if(page == 2){
            if(!carNumber?.replace(/[0-9]/gi,"")){
                setPage(1);
            }
        }
    }, [page]);

    useEffect(() => {
        setErrMsg(null);

        if(carNumber.length == 3){
            setApiChk(true);
            setTimeout(() => {
                if(textChk == 2){
                    if(subTextChk){
                        setPage(3);
                    }else{
                        setPage(2);
                    }
                }else{
                    setPage(1);
                    setTextChk(3);
                }
                setApiChk(false);
            }, 100);
        }

        if(carNumber.length == 4){
            setApiChk(true);
            setTimeout(() => {
                if(textChk !== 2){
                    if(subTextChk){
                        setPage(3);
                        setSubTextChk(true);
                    }else{
                        setPage(2);
                    }
                }
                setApiChk(false);
            }, 100);
        }

        if(textChk == 2 && carNumber.length == 7 && subTextChk || textChk == 3 && carNumber.length == 8 && subTextChk){
            setApiChk(true);
            setTimeout(() => {
                setPage(4);
                setApiChk(false);
            }, 100);
        }
    }, [carNumber]);

    return (
        <PageSizing>
            <Header
                logoClickChk={true}
                carNumber={null}
            />
            <ContentsSection addClass="headerContents">
                <PageTitle
                    title={"고객님의 차량번호를 입력해주세요."}
                    subTitle={"차량번호 입력"}
                />
                <PageContentsBox>
                    <PageInputItem addClass={errMsg ? "err carNumberInputItem" : "carNumberInputItem"}>
                        <CarNumberItem data={
                            `<span>${carNumber}</span>${textChk == 2 ? carNumberSubPlaceholder.substring(carNumber.length ? carNumber.length : 0,carNumberSubPlaceholder.length + 1) : carNumberPlaceholder.substring(carNumber.length ? carNumber.length : 0,carNumberSubPlaceholder.length + 1)}`
                        }/>
                    </PageInputItem>
                    <KeypadTitle
                        title={page == 0 ? "<span class='gTextColor'>차량번호 앞자리</span>를 입력해주세요."
                            : page == 1 ? "<span class='gTextColor'>자음</span>을 먼저 선택해주세요."
                            : page == 2 ? "해당되는 <span class='gTextColor'>글자</span>를 선택해주세요."
                            : page == 3 ? "<span class='gTextColor'>나머지 차량번호</span>를 입력해주세요."
                            : page == 4 ? "차량번호를 잘 입력하셨나요?" : ""}
                        subTitle={page == 0 ? "앞자리가 2자리면, 입력하시고 화살표 클릭" : ""}
                        errMsg={errMsg}
                    />
                    <KeypadBox>
                        {page == 4 ? <KeypadMsg text="입력한 정보가 맞다면 화살표를 클릭해주세요!"/> : ""}
                        <Keypad
                            addClass={page == 2 ? "col2" : ""}
                            type={page == 1 ? "text" : page == 2 ? "textSub" : "number"}//number, text, textSub
                            textDisabled={page == 4 ? true : false}
                            nextChk={carNumber.length == 2 && page !== 1 || page == 4 ? true : false}
                            textVal={page == 2 ? carNumber?.replace(/[0-9]/gi,"")||null : null}
                            data={carNumber}
                            textFunc={(val)=>{
                                if(carNumber.length < (textChk == 2 ? 7 : 8) && !apiChk){
                                    setCarNumber(String(carNumber) + String(val))
                                }
                            }}
                            textSubFunc={(val)=>{
                                if(carNumber.length < (textChk == 2 ? 7 : 8) && !apiChk){
                                    setCarNumber(String(carNumber.slice(0,-1)) + String(val))
                                    setSubTextChk(true);
                                }
                            }}
                            backFunc={()=>{
                                if(carNumber.length > 0 && !apiChk){
                                    if(page == 4){
                                        if(carNumber.length - 1 < (textChk == 2 ? 7 : 8)){
                                            setPage(3);
                                        }
                                    }else if(page == 3){
                                        if(textChk == 2){
                                            if(carNumber.length - 1 < 3){
                                                setTextChk(null);
                                                setSubTextChk(null);
                                                setPage(0);
                                            }
                                        }else{
                                            if(carNumber.length - 1 < 4){
                                                setTextChk(null);
                                                setSubTextChk(null);
                                                setPage(1);
                                            }
                                        }
                                    }else if(page == 2){
                                        setPage(1);
                                    }else if(page == 1){
                                        setPage(0);
                                    }

                                    setTimeout(() => {
                                        setCarNumber(carNumber.slice(0,-1))
                                    }, 100);
                                }
                            }}
                            nextFunc={()=>{
                                if(carNumber.length == 2 && page !== 1){
                                    setPage(1);
                                    setTextChk(2);
                                }else if(page == 4){
                                    navigate("/service",{state: {carNumber:carNumber}});
                                }
                            }}
                            allDel={false}
                        />
                    </KeypadBox>
                </PageContentsBox>
            </ContentsSection>
        </PageSizing>
    );
};

export default CarNumber;