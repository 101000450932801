import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";

const Main = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing>
            <ContentsSection addClass="btnContents">
                <div className="landing_logoBox">
                    <img src="/assets/images/app/logo.svg"/>
                </div>
            </ContentsSection>
            {navigator.onLine ? 
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"시작하기"}
                        disabled={false}
                        func={() => {navigate("/service/login")}}
                    />
                </BtnBox>
            :""}
        </PageSizing>
    );
};

export default Main;