import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import { PageContentsBox, StatePageBox } from "component/app/setting";
import { TicketItem } from "component/product/service";
import {Popup} from "../../component/basic/popup";

const Service = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const carNumber = state?.state?.carNumber||null;

    const listApi = useGet({
        url:`/service?car_number=${carNumber}`,
        loginType:"login"
    });
    const listData = listApi?.data?.car_wash_reservations;

    const [nickName,setNickName] =  useState(null);
    const [cash,setCash] =  useState(null);
    const [popupData,setPopupData] =  useState(null);

    useEffect(() => {
        if(listApi){
            if(listApi?.data?.nickname){
                setNickName(listApi?.data?.nickname);
            }
            if(listApi?.data?.cash){
                setCash(listApi?.data?.cash);
            }
            if(listApi?.success == false || listApi?.success == "false" || (listData?.length <= 0 && listApi?.data?.detailing_reservations?.length <= 0)){
                navigate("/service/order",{state: {carNumber:carNumber,notMember:true}});
            }
        }
    }, [listApi]);

    window.barcodeCheck = (barcode) => {
        barcode = JSON.parse(barcode)
        if (barcode?.type === "car_wash") {
            navigate("/service/order",{state: {page:1,id:barcode.id,name:'',carNumber:carNumber,cash:cash,productId:'',productType:'car_wash'}});
        } else if (barcode?.type === "qr") {
            navigate("/service/order",{state: {page:1,id:barcode.id,name:'',carNumber:carNumber,cash:cash,productId:'',productType:'qr'}});
        } else if (barcode?.type === "detailing") {
            navigate("/service/order",{state: {page:1,id:barcode.id,name:'',carNumber:carNumber,cash:cash,productId:'',productType:'detailing'}});
        } else if (barcode?.type === "coupon") {
            setPopupData({
                addClass:"type2",
                title:"인식 실패",
                text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                icon:"/assets/images/img/err_icon.svg",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{ setPopupData(null)},
                btn0:"다시 시도",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        } else {
            setPopupData({
                addClass:"type2",
                title:"인식 실패",
                text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                icon:"/assets/images/img/err_icon.svg",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{ setPopupData(null)},
                btn0:"다시 시도",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    };

    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={carNumber}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                        title={"<span class='gTextColor'>보유하고 계신 이용권</span>을 선택해주세요."}
                        subTitle={`${nickName ? nickName : "고객"}님, 환영합니다!`}
                    />
                    <PageContentsBox addClass="pageContentsScrollBox">
                        {listApi?.data?.detailing_reservations && listApi?.data?.detailing_reservations?.map((item,i)=>(
                            <TicketItem
                                key={i}
                                addClass=""
                                type={item.type}
                                title={item.name}
                                text={item.contents}
                                btnText={`이용권 사용 (${item?.date} ${item?.time} 예약)`}
                                func={()=>{
                                    navigate("/service/order",{state: {page:1,id:item.id,name:item.name,carNumber:carNumber,cash:cash,productId:item.product_id,productType:item.product_type}});
                                }}
                            />
                        ))}
                        {listData && listData?.map((item,i)=>(
                            <TicketItem
                                key={i}
                                addClass=""
                                type={item.type}
                                title={item.name}
                                text={item.contents}
                                expiration_date={item.expiration_date}
                                btnText="이용권 사용"
                                func={()=>{
                                    navigate("/service/order",{state: {page:1,id:item.id,name:item.name,carNumber:carNumber,cash:cash,productId:item.product_id,productType:item.product_type}});
                                }}
                            />
                        ))}
                    </PageContentsBox>
            </ContentsSection>
            <BtnBox
                boxType="fixed"
                addClass={"col2"}
            >
                <BtnItem
                    addClass="subBtn"
                    contents={"<img src='/assets/images/icon/ch.svg'/>차량번호 변경"}
                    disabled={false}
                    func={() => {navigate("/carNumber")}}
                />
                <BtnItem
                    addClass=""
                    contents={"다른 이용권 보기"}
                    disabled={false}
                    func={() => {navigate("/service/order",{state: {carNumber:carNumber,cash:cash}})}}
                />
            </BtnBox>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Service;