import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { Header } from "component/elements/header";
import { Keypad, KeypadTitle, PageContentsBox, PageInputItem, PwItem } from "component/app/setting";

const PwEdit = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [pw,setPw] =  useState("");
    const [newPw,setNewPw] =  useState("");
    const [newPwChk,setNewPwChk] =  useState("");
    const [errMsg,setErrMsg] =  useState(null);
    const [apiChk,setApiChk] =  useState(false);

    useEffect(() => {
        setErrMsg(null);
    }, [page]);

    useEffect(() => {
        setErrMsg(null);

        if(newPwChk.length == 6){
            if(newPwChk == newPw){
                setApiChk(true);
                let formData = new FormData();
                formData.append("old_password", pw);
                formData.append("password", newPw);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType:"login",
                    url: `/password/modify`,
                    success: (data) => {
                        setApiChk(false);
                        sessionStorage.setItem("pwChChk",true);
                        navigate(-1);
                    },
                    err: (data) => {
                        setApiChk(false);
                        if(data.data || data.alert){
                            setErrMsg(data.data);
                        }
                    }
                })
            }else{
                setErrMsg("비밀번호가 일치하지 않습니다.");
            }
        }
    }, [newPwChk]);

    useEffect(() => {
        setErrMsg(null);

        if(newPw.length == 6){
            setPage(2);
        }
    }, [newPw]);

    useEffect(() => {
        setErrMsg(null);

        if(pw.length == 6){
            setApiChk(true);
            let formData = new FormData();
            formData.append("password", pw);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/password/check`,
                success: (data) => {
                    setApiChk(false);
                    setPage(1);
                },
                err: (data) => {
                    setApiChk(false);
                    if(data.data || data.alert){
                        setErrMsg(data.data);
                    }
                }
            })
        }
    }, [pw]);

    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={null}
            />
            <ContentsSection addClass="headerContents">
                <PageTitle
                    addClass={"flexTitle"}
                    title={"<img src='/assets/images/icon/setting.svg'/>비밀번호 재설정"}
                    subTitle={"관리자 전용"}
                />
                <PageContentsBox>
                    <PageInputItem addClass={errMsg ? "err" : ""}>
                        <PwItem data={page == 0 ? pw : page == 1 ? newPw : page == 2 ? newPwChk : ""}/>
                    </PageInputItem>
                    <KeypadTitle
                        title={
                            page == 0 ? "<span class='gTextColor'>현재 비밀번호</span>를 입력해주세요."
                            : page == 1 ? "<span class='gTextColor'>변경할 비밀번호</span>를 입력해주세요."
                            : page == 2 ? "변경할 비밀번호를 <span class='gTextColor'>한 번 더</span> 입력해주세요." : ""}
                        subTitle={null}
                        errMsg={errMsg}
                    />
                    <Keypad
                        type={"number"}//number, text, textSub
                        textDisabled={false}
                        nextChk={false}
                        textVal={null}
                        data={page == 0 ? pw : page == 1 ? newPw : page == 2 ? newPwChk : ""}
                        textFunc={(val)=>{
                            if(page == 0){
                                if(pw.length < 6 && !apiChk){
                                    setPw(String(pw) + String(val))
                                }
                            }else if(page == 1){
                                if(newPw.length < 6 && !apiChk){
                                    setNewPw(String(newPw) + String(val))
                                }
                            }else if(page == 2){
                                if(newPwChk.length < 6 && !apiChk){
                                    setNewPwChk(String(newPwChk) + String(val))
                                }
                            }
                        }}
                        textSubFunc={(val)=>{
                            if(page == 0){
                                if(pw.length < 6 && !apiChk){
                                    setPw(String(pw) + String(val))
                                }
                            }else if(page == 1){
                                if(newPw.length < 6 && !apiChk){
                                    setNewPw(String(newPw) + String(val))
                                }
                            }else if(page == 2){
                                if(newPwChk.length < 6 && !apiChk){
                                    setNewPwChk(String(newPwChk) + String(val))
                                }
                            }
                        }}
                        backFunc={()=>{
                            if(page == 0){
                                if(pw.length > 0 && !apiChk){
                                    setPw(pw.slice(0,-1))
                                }
                            }else if(page == 1){
                                if(newPw.length > 0 && !apiChk){
                                    setNewPw(newPw.slice(0,-1))
                                }
                            }else if(page == 2){
                                if(newPwChk.length > 0 && !apiChk){
                                    setNewPwChk(newPwChk.slice(0,-1))
                                }
                            }
                        }}
                        nextFunc={()=>{}}
                        allDel={()=>{
                            if(!apiChk){
                                if(page == 0){
                                    setPw("")
                                }else if(page == 1){
                                    setNewPw("")
                                }else if(page == 2){
                                    setNewPwChk("")
                                }
                            }
                        }}
                    />
                </PageContentsBox>
            </ContentsSection>
        </PageSizing>
    );
};

export default PwEdit;